.profession {
  display: flex;
  overflow: hidden;
  background: linear-gradient(270deg, #1ea4ac, #068291);

  .width {
    width: 1140px;

    .middle {
      display: flex;
      height: 100%;
      align-items: center;

      .profession-text-box {
        background: transparent;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(20px);
        padding: 20px;
        max-width: 550px;

        h2 {
          font-size: 62px;
          font-weight: 400;
          color: #fff;
          margin-bottom: 25px;

          @media only screen and (max-width: 560px) {
            font-size: 36px;
          }
        }

        p {
          font-size: 20px;
          color: #fff;
        }

        @media only screen and (max-width: 955px) {
          width: 100%;
          max-width: max-content;
        }
      }
    }
  }

  .cards-container {
    position: relative;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 540px) {
      height: 300px;
    }
  }

  .card {
    position: absolute;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.5s ease, opacity 0.5s ease;
    width: calc(100% - 50px);

    h3 {
      font-size: 32px;
      text-align: center;

      @media only screen and (max-width: 600px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @media only screen and (max-width: 555px) {
        font-size: 26px;
      }
    }

    @media only screen and (max-width: 555px) {
      height: 150px;
    }
  }

  .card.active {
    opacity: 1;
    z-index: 10;
    transform: translateY(0) scale(1.1);
    transition: transform 0.5s ease, opacity 0.5s ease;

    h3 {
      font-size: 32px;

      @media only screen and (max-width: 555px) {
        font-size: 26px;
      }
    }
  }

  .card:not(.active) {
    opacity: 0.5;
    transition:
      transform 0.5s ease,
      opacity 0.5s ease;

    h3 {
      font-size: 32px;

      @media only screen and (max-width: 555px) {
        font-size: 26px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: 60px;
  }
}

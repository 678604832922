.contact {
    background: #1ea4ac;
    padding-bottom: 130px;

    .contact-title {
        text-align: center;
        color: #fff;
        margin: 0;
        font-size: 32px;
        font-weight: 400;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 60px;
        padding-bottom: 32px;
        border-bottom: 1px solid #fff;

        @media only screen and (max-width: 660px) {
            font-size: 60px;
            line-height: 60px;
        }
    }

    .paragraph-title {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 100px;
        color: #fff;
        text-align: center;
        margin-top: 32px;

        @media only screen and (max-width: 660px) {
            font-size: 22px;
        }
    }

    .question__title {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #fff;
        transition: 0.3s;
        cursor: pointer;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 2px solid #fff;

        &:hover {
            transition: 0.3s;
            color: #fff;
        }

        svg {
            font-size: 20px;
            transition: 0.3s;
        }

        h4 {
            margin: 0;
            font-size: 22px;
        }
    }

    .active__arrow {
        color: #fff;

        svg {
            transform: rotate(90deg);
            transition: 0.3s;
        }
    }

    .active__text {
        height: 60px !important;
        transition: 0.3s;
    }

    .personal__card {
        border-radius: 50px;
        border: 2px solid #fff;
        padding: 40px 25px;
        display: grid;
        gap: 20px;

        input,
        textarea {
            width: 100%;
            padding: 13px 20px;
            border-radius: 50px;
            border: none;
            outline: none;
            border: 2px solid transparent;
            transition: 0.5s;
            resize: none;

            &:focus {
                border: 2px solid #068291;
                transition: 0.5s;
            }

            &::placeholder {
                font-size: 16px;
                font-weight: 500;
                color: #969696;
            }
        }

        textarea {
            border-radius: 20px;
            font-size: 16px;
        }

        button {
            text-transform: uppercase;
            border: 2px solid #fff;
            background-color: transparent;
            cursor: pointer;
            border-radius: 50px;
            color: #fff;
            font-size: 16px;
            padding: 18px 40px;
            font-weight: 600;
            transition: 0.5s;

            &:hover {
                transition: 0.5s;
                background-color: #fff;
                color: #1ea4ac;
            }
        }
    }

    .questions p {
        color: #fff;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 8px;
        margin-top: 8px;
        height: 0;
        overflow: hidden;
        transition: 0.3s;
    }
}

.questions {
    @media only screen and (max-width: 998px) {
        margin-bottom: 60px;
    }
}

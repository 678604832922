.about {
    padding-bottom: 60px;

    .company-description {
        text-align: center;
        margin-bottom: 40px;
        padding-top: 60px;
    
        h3 {
            text-align: center;
            color: #fff;
            margin: 0;
            font-size: 32px;
            font-weight: 400;
            border-bottom: 1px solid #fff;
            padding-bottom: 32px;
        }
    
        p {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 100px;
            color: #fff;
            text-align: center;
            margin-top: 32px;
        }
    }
    
    .technologies {
        text-align: center;
    
        h4 {
            text-align: center;
            color: #fff;
            margin: 0;
            font-size: 26px;
            font-weight: 300;
            margin-bottom: 35px;
        }
    
        .technology-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
    
            .technology-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100px;
                text-align: center;
                transition: .5s;
    
                img {
                    width: 60px;
                    height: 60px;
                    object-fit: contain;
                    margin-bottom: 20px;
                }
    
                p {
                    font-size: 16px;
                    color: #fff;
                    margin: 0;
                }

                &:hover{
                    scale: 1.3;
                    transition: .5s;
                }
            }
        }
    }
}
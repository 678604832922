header {
    color: #555;
    backdrop-filter: blur(0px);
    background-color: transparent;
    z-index: 1;
    width: 100%;
    border: 0px;
    position: fixed;
    z-index: 1000;

    .center {
        display: flex;
        justify-content: center;
        position: relative;

        .header-container {
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            padding: 5px 20px;
            height: 50px;
            margin-top: 10px;
            max-width: 1140px;
            width: calc(100% - 10px);
            border-radius: 25px;
            transition: 0.5s;
            cursor: pointer;
            background: #fff;

            .flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;

                svg {
                    font-size: 24px;
                }
            }

            &.scrolled {
                max-width: 375px;
                transition: 0.5s;
                color: #555555;
                background-color: #fff;
            }

            @media only screen and (max-width: 415px) {
                width: calc(100% - 10px);
            }
        }

        .absolute {
            position: absolute;
            top: 10px;

            .header-dropdown {
                width: 1140px;
                max-width: 1140px;
                background: #bec9cb;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5.6px);
                -webkit-backdrop-filter: blur(5.6px);
                border: 0;
                z-index: 999;
                color: #555;
                border-radius: 20px;
                transition: 0.5s;
                height: 0;
                overflow: hidden;

                .dropdown-top {
                    text-align: center;
                    border-bottom: 1px solid #fff;
                    padding-bottom: 20px;

                    .logo {
                        width: 200px;
                    }
                }

                .dropdown-bottom {
                    padding-top: 10px;
                    padding-bottom: 10px;

                    ul {
                        list-style-type: none;

                        li {
                            text-align: center;

                            a {
                                padding-top: 10px;
                                padding-bottom: 10px;
                                width: 100%;
                                cursor: pointer;
                                transition: 0.3s;
                                font-size: 16px;
                                line-height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 8px;
                                text-decoration: none;
                                color: #555;

                                &:hover {
                                    background: rgba(230, 230, 230, 0.7);
                                    transition: 0.3s;
                                }
                            }

                            .active {
                                background: rgba(230, 230, 230, 0.7);
                            }
                        }
                    }
                }

                &.small {
                    max-width: 375px;
                    transition: 0.5s;
                }

                &.show {
                    display: block;
                    padding: 20px;
                    height: 515px;
                }

                @media only screen and (max-width: 1170px) {
                    width: auto;
                    max-width: auto;
                }
            }

            @media only screen and (max-width: 1170px) {
                width: 100%;
            }
        }
    }
}

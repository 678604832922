.follows {
    background: url(../../images/icons.png), rgba(29, 61, 98, 255);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 350px;
    justify-content: center;
    position: relative;
    width: 100%;

    .center {
        display: flex;
        justify-content: center;
        align-items: center;

        .flex {
            display: flex;
            align-items: center;
            gap: 20px;

            h3{
                font-size: 32px;
                color: #fff;
                text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
            }

            .social {
                display: flex;
                align-items: center;
                gap: 10px;

                a {
                    display: flex;
                    align-items: center;
                    align-items: center;
                    background-color: #fff;
                    border-radius: 50%;
                    color: #1ea4ac;
                    display: flex;
                    font-size: 20px;
                    justify-content: center;
                    padding: 16px;
                    transition: 0.5s;
                    gap: 10px;
                    border: 1px solid #1ea4ac;

                    &:hover{
                        background-color: #1ea4ac;
                        color: #fff;
                        border: 1px solid #fff;
                    }
                }

                @media only screen and (max-width: 767px) {
                    justify-content: center;
                }
            }

            @media only screen and (max-width: 767px) {
                display: grid;
            }
        }
    }

    .top-img {
        position: absolute;
        width: 100%;
        top: -5px;
    }

    .bottom-img {
        position: absolute;
        width: 100%;
        bottom: -5px;
    }

    @media only screen and (max-width: 767px) {
        height: 250px;
    }
}

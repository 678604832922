* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #7bbdbe;
}

::-webkit-scrollbar-thumb {
  background: #068291;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0d506d;
}

.container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 1170px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  max-width: none;
  width: calc(100% + 20px);

  .col-25 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(25% - 30px);
  }

  .col-33 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(33.33% - 30px);

    @media only screen and (max-width: 955px) {
      width: calc(100% - 30px);
      display: block;
    }
  }

  .col-50 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(50% - 30px);

    @media only screen and (max-width: 955px) {
      width: calc(100% - 30px);
      display: block;
    }
  }

  .col-66 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(66.66% - 30px);

    @media only screen and (max-width: 955px) {
      width: calc(100% - 30px);
      display: block;
    }
  }

  .col-75 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(75% - 30px);
  }
}

.background{
  background-image: linear-gradient(-27.35deg, #068291 50%, #1ea4ac 50%);
}
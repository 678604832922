.portfolio {
    padding-top: 80px;
    padding-bottom: 80px;

    h3 {
        text-align: center;
        color: #000;
        margin: 0;
        font-size: 32px;
        font-weight: 400;
        border-bottom: 1px solid #000;
        padding-bottom: 32px;
    }

    .portfolio-cards {
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 32px;
        margin-left: 5px;
        margin-right: 5px;

        .portfolio-card {
            padding: 30px;
            background: rgba(255, 255, 255, 0.7);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5.6px);
            text-decoration: none;
            border-radius: 10px;
            cursor: pointer;

            img {
                width: 100%;
                border-radius: 10px;
            }

            h4 {
                color: #555;
                font-size: 22px;
                margin-top: 30px;
                font-weight: 300;
                transition: 0.5s;
            }

            &:hover {
                h4 {
                    color: #1ea4ac;
                    transition: 0.5s;
                }
            }

            @media only screen and (max-width: 988px) {
                padding: 20px;
            }
        }

        @media only screen and (max-width: 988px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 695px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

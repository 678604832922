.choose {
    // background-image: linear-gradient(-27.35deg, #068291 50%, #1ea4ac 50%);
    padding-top: 60px;
    padding-bottom: 60px;

    .panel {
        padding: 45px 30px;
        background: rgba(255, 255, 255, 0.7);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.6px);
        border-radius: 15px;
        min-height: 320px;

        h3 {
            margin-bottom: 25px;
            text-align: center;
            color: #222;
            line-height: 1.05;
            font-weight: 400;
            font-size: 32px;
        }

        p {
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: #222222;
        }

        @media only screen and (max-width: 955px) {
            height: auto;
            min-height: auto;
            margin-bottom: 15px !important;
        }
    }

    .mt-25 {
        margin-top: 25px;

        @media only screen and (max-width: 955px) {
            margin-top: 0;
        }
    }

    .mb-50 {
        margin-bottom: 50px;
    }

    .justify-center {
        display: flex;
        justify-content: center;

        a {
            padding: 12px 24px;
            background: transparent;
            border: 3px solid #1ea4ac;
            border-radius: 15px;
            font-size: 16px;
            font-weight: 300;
            color: #1ea4ac;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            transition: 0.3s;

            &:hover {
                color: #fff;
                background-color: #1ea4ac;
            }
        }
    }
}

@keyframes slideDiagonalBackground {
    0% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(25%);
    }
}

.services {
    padding-top: 60px;
    padding-bottom: 60px;

    h3 {
        text-align: center;
        color: #000;
        margin: 0;
        font-size: 32px;
        font-weight: 400;
        border-bottom: 1px solid #000;
        padding-bottom: 32px;
    }

    .gap-15 {
        @media only screen and (max-width: 955px) {
            gap: 15px;
        }
    }

    .mt-32 {
        margin-top: 32px;

        .services-card {
            background: rgba(255, 255, 255, 0.7);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            -webkit-backdrop-filter: blur(5.6px);
            backdrop-filter: blur(5.6px);
            padding: 25px;
            border-radius: 10px;
            min-height: 175px;
            transition: 0.5s;

            h4 {
                font-size: 22px;
                margin-bottom: 15px;
                text-align: center;
                color: #555;
                font-weight: 400;
            }

            p {
                text-align: center;
                color: #555;
                font-size: 16px;
            }

            &:hover {
                scale: 1.05;
                transition: 0.5s;
            }
        }
    }
}

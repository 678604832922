.hero {
    background: linear-gradient(270deg, #1ea4ac, #068291);
    min-height: 100vh;
    padding-bottom: 80px;

    .welcome {
        margin-left: auto;
        margin-right: auto;
        max-width: 570px;
        color: #fff;
        text-align: center;
        padding-top: 240px;

        h1 {
            font-size: 72px;
            font-weight: bold;
        }

        p {
            margin-top: 30px;
            margin-bottom: 0;
            color: rgba(255, 255, 255, 0.8);
            max-width: 570px;
            line-height: 30px;
            margin-bottom: 160px;
        }
    }

    .left-panel {
        background: transparent;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(20px);
        border-radius: 15px;
        padding: 45px 30px;
        min-height: 600px;

        h2 {
            font-size: 42px;
            color: #fff;
            font-weight: 400;

            .underline {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: calc(0.3125em* -0.625);
                    height: 0.3125em;
                    background-image: url(../../images/underline.svg);
                    background-size: auto 100%;
                }
            }

            @media only screen and (max-width: 600px) {
                font-size: 36px;
            }
        }

        p {
            color: #fff;
            margin-top: 50px;
            font-size: 32px;
            text-align: right;
            line-height: 1.05;

            @media only screen and (max-width: 600px) {
                font-size: 22px;
            }
        }

        @media only screen and (max-width: 955px) {
            min-height: auto;
            margin-bottom: 15px;
        }

        @media only screen and (max-width: 600px) {
            padding: 30px 20px;
        }
    }

    .right-panel {
        background: rgba(255, 255, 255, 0.85);
        box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        border-radius: 15px;
        padding: 45px 30px;
        min-height: 600px;

        h2 {
            font-size: 42px;
            font-weight: 400;
            color: #555;
            line-height: 50px;

            @media only screen and (max-width: 600px) {
                font-size: 36px;
                line-height: 42px;
            }
        }

        p {
            margin-top: 50px;
            margin-bottom: 26px;
            color: #000;
            font-size: 20px;
            text-align: left;
            font-weight: 300;
            letter-spacing: 2px;
            line-height: 30px;
            text-align: justify;

            @media only screen and (max-width: 600px) {
                margin-top: 30px;
                font-size: 16px;
                text-align: left;
                margin-bottom: 13px;
            }
        }

        @media only screen and (max-width: 955px) {
            min-height: auto;
        }

        @media only screen and (max-width: 600px) {
            padding: 30px 20px;
        }
    }

    @media only screen and (max-width: 600px) {
        padding-bottom: 60px;
    }

}